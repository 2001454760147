import React from 'react';
import Files from "../../../../../../Commun/Inputs/Files/Files.jsx";

const FieldsResilRib = (props) => {
    const {setFieldValue, values} = props

    return (
        <>
            <div className="alert alert-info mt-5" role="alert">
                Suite à la résiliation, il est possible que nous devions rembourser un trop-perçu.<br/>Merci de nous
                transmettre votre RIB afin de sécuriser ce remboursement sur votre compte.
            </div>

            <Files
                label={'RIB'}
                name={`files[G]`}
                arrayName={'files'}
                value={values.files}
                setFieldValue={setFieldValue}
                code={'G'}
                accept={'image/*,application/pdf'}
            />
        </>
    );
};

export default FieldsResilRib;