import React, {useState} from 'react';
import {Formik} from "formik";
import {normalizeIban} from "../../../../../../utils/functions";
import SmallSpinner from "../../../../../Commun/Spinners/SmallSpinner";
import ChangeTitulaireContainer from "./ChangeTitulaire/ChangeTitulaireContainer";
import Files from "../../../../../Commun/Inputs/Files/Files.jsx";
import Swal from "sweetalert2";
import IbanField from "../../../../../Commun/Inputs/IbanField/IbanField.jsx";


const ChangeRib = (props) => {
    const {initialValues, query, loading, validate, postAvenantChangeIban, iban, contrat} = props
    const [changeTitulaire, setChangeTitulaire] = useState(false)
    const souscripteur = contrat ? contrat.souscripteur.nom : null

    const handleFormSubmit = (values) => {
        if( changeTitulaire && values?.relationship === 'A' && values.files['G'] === undefined) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de nous transmettre votre RIB.',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else {
            postAvenantChangeIban({query, form: values, changementTitulaire: changeTitulaire, successFunction: () => props.history.push('/mon-profil')});
        }
    }

    return (
        <div>
            <div className={'mt-5'}>
                <h1 className={'fw-bold'}>Je modifie mes coordonnées bancaires</h1>
            </div>

            <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validate={values => validate(values, changeTitulaire)} onSubmit={values => handleFormSubmit(values)}>
                {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                    <form onSubmit={handleSubmit}>
                        <div className={'mt-4'}>
                            <div className="row">
                                <div className="col">
                                    <p className={'mb-1 fw-bold'}>Mon IBAN actuel</p>
                                    <p className={'m-0 text-secondary'}>{iban ? normalizeIban(iban) : 'Non renseigné'}</p>
                                </div>
                            </div>
                        </div>

                        <div className={'mt-4'}>
                            <p className={'fw-bold'}>Mes nouvelles coordonnées</p>
                        </div>

                        <ChangeTitulaireContainer
                            souscripteur={souscripteur}
                            contrat={contrat}
                            changeTitulaire={changeTitulaire}
                            setChangeTitulaire={setChangeTitulaire}
                            setFieldValue={setFieldValue}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}/>

                        <div className="my-3">
                            <IbanField />
                        </div>

                        {
                            (changeTitulaire && contrat.id  && values.titulaire && _.toUpper(_.trim(souscripteur)) !== _.toUpper(_.trim(values.titulaire))) &&
                            <div className="my-5">
                                <div className="alert alert-info" role="alert">
                                    Merci de nous transmettre votre nouveau RIB.
                                </div>

                                <Files
                                    label={'RIB'}
                                    name={`files[G]`}
                                    arrayName={'files'}
                                    value={values.files}
                                    setFieldValue={setFieldValue}
                                    code={'G'}
                                />
                            </div>
                        }

                        <p><i className="bi bi-exclamation-triangle me-2" />Attention :</p>
                        <p>- Une mensualité / annuité peut encore être prélevée sur vos anciennes coordonnées bancaires.</p>

                        <p>- La modification de l'IBAN concerne tous vos contrats.</p>

                        <div className="d-grid gap-2 mt-4">
                            {
                                loading ? <SmallSpinner/> :
                                    <button type="submit" className={'btn btn-primary btn-block'}>
                                    Je valide mes nouvelles coordonnées bancaires
                                    </button>
                            }
                        </div>
                    </form>
                    )}
            </Formik>
        </div>
);
};


export default ChangeRib;
