import {connect} from 'react-redux'
import ChangeTitulaire from "./ChangeTitulaire";
import {getTitulaireBanqueUser} from "../../../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        titulaire: getTitulaireBanqueUser(state),
    }
}

const ChangeTitulaireContainer = connect(mapStateToProps)(ChangeTitulaire)

export default ChangeTitulaireContainer