import {connect} from 'react-redux'
import ChangeRib from "./ChangeRib";
import {amendmentChangeIbanGetLink, getContractsLink} from "../../../../../../redux/selectors/links/links.selectors";
import {validate} from "./validate";
import {
    POST_AMENDMENT_CHANGE_IBAN,
    postAvenantChangeIban
} from "../../../../../../redux/actions/app/amendment/amendment.actions";
import {
    getIbanBanqueUser,
    getIdUser,
    getTitulaireBanqueUser
} from "../../../../../../redux/selectors/user/user.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import _ from "lodash";
import {
    getDataContracts,
    getDataFirstContractInProgress
} from "../../../../../../redux/selectors/contracts/contracts.selectors.js";
import {CONTRACTS, fetchContracts} from "../../../../../../redux/actions/app/contracts/contracts.actions.js";
import {compose} from "redux";
import loader from "../../../../../Commun/Spinners/Loader/Loader.jsx";

const mapStateToProps = state => {
    const contracts = getDataContracts(state);

    return {
        query_contracts: getContractsLink(state),
        query: amendmentChangeIbanGetLink(state),
        loading: getLoadingEntity(state, POST_AMENDMENT_CHANGE_IBAN),
        contrat: !_.isEmpty(contracts) ? (getDataFirstContractInProgress(state) || contracts[0]) : {},
        loaded: getLoadingEntity(state, CONTRACTS) === false,
        validate,
        iban: getIbanBanqueUser(state),
        initialValues: {
            tiers: getIdUser(state),
            adresse: '',
            cp: '',
            ville: '',
            titulaire: '',
            type: '',
            firstname: '',
            lastname: '',
            birthname: '',
            birthdate: '',
            birthcity: '',
            birthcountry: '',
            relationship: '',
            third_payeur_reason: '',
            files: []
        }
    }
}


const mapDispatchToProps ={
    postAvenantChangeIban,
    fetchContracts
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchContracts } = dispatchProps
    const { query_contracts, contracts } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => _.isEmpty(contracts) ? fetchContracts({query: query_contracts}) : null,
    }
}

const ChangeRibContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(ChangeRib)

export default ChangeRibContainer
