import { memo, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import SmallSpinner from "../../Spinners/SmallSpinner.jsx";
import {useIban} from "../../../../api/services.js";
import Text from "../Text/Text.jsx";

const IbanField = memo(({ nameIban = 'iban' }) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, meta] = useField(nameIban);
    const queryIBAN = useIban(values?.[nameIban]);

    const errorMessage = queryIBAN.error?.response?.data?.message;

    useEffect(() => {
        if (queryIBAN.isSuccess && queryIBAN.data?.bic) {
            setFieldValue('bic', queryIBAN.data.bic);
            setFieldValue('domiciliation', queryIBAN.data.bank);
        } else if (queryIBAN.isError && !queryIBAN.isFetching) {
            setFieldValue('bic', '');
            setFieldValue('domiciliation', '');
        }
    }, [queryIBAN.isSuccess, queryIBAN.isError]);

    return (
        <div className="row">
            <div className="col">
                <Text
                    label={'IBAN'}
                    name={nameIban}
                    value={field.value}
                    onChange={(e) => {
                        field.onChange(e);
                        setFieldValue('bic', '');
                        setFieldValue('domiciliation', '');
                    }}
                    onBlur={field.onBlur}
                    error={meta.touched && (meta.error || (queryIBAN.isError && !queryIBAN.isFetching && errorMessage))}
                />
                {queryIBAN.isLoading && <SmallSpinner />}
            </div>
            <div className="col">
                <Text
                    label={'Domiciliation de la banque'}
                    name="domiciliation"
                    value={values.domiciliation || ''}
                    disabled
                    normalize={v => v.toUpperCase()}
                />
            </div>
            <div className="col">
                <Text
                    label={'Code BIC'}
                    name="bic"
                    value={values.bic || ''}
                    disabled
                    normalize={v => v.toUpperCase()}
                />
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.values === nextProps.values;
});

export default IbanField;
